<template>
  <div class="legal">
    <heade></heade>
    <div class="cen_wrap" :style="{
      backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
    }">
      <div class="cen_box nice-scroll">
        <div class="cen" v-html="articleh.content"></div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import $ from "jquery";
import { scroll } from "@utils/scroll";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import { getArticle } from "@api/public";
export default {
  components: {
    heade,
    foot,
  },
  props: {},
  data() {
    return {
      articleh: [],
      queryWhere: {},
      backgroud: "",
    };
  },
  mounted: function () {
    let that = this;
    getArticle({ id: this.queryWhere.id }).then(function (res) {
      that.$set(that, "articleh", res.data);
    });
    scroll($(".nice-scroll"));
  },
  created: function () {
    document.title = this.$route.meta.title;
    this.queryWhere.id = this.$route.meta.id;
    this.backgroud = this.$route.meta.background;
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>

<style>
.legal .cen_wrap {
  width: 100%;
  height: 1280px;
  background-size: cover;
  background-position: center 0;
}

.legal .cen_box {
  width: 1324px;
  height: 929px;
  position: relative;
  top: 188px;
  left: 285px;
  box-sizing: border-box;
  padding: 120px 100px;
}

.cen_box>.cen {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.cen_box>.cen::-webkit-scrollbar {
  width: 0;
}

.cen img {
  width: 800px;
}

.cen_box p {
  font-size: 16px;
  letter-spacing: 3px;
}
</style>